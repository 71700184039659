import React, { useRef, useEffect } from 'react';
import { gsap, Power1 } from 'gsap';
import './Hero.css';

function Hero() {
    const textARef = useRef(null);
    const textProfessionalRef = useRef(null);
    const textAsRef = useRef(null);
    const textUIUXRef = useRef(null);
    const textDesignerRef = useRef(null);
    const textWithFirstRef = useRef(null);
    const textMoreRef = useRef(null);
    const textThanRef = useRef(null);
    const textSixRef = useRef(null);
    const textYearsRef = useRef(null);
    const textOfRef = useRef(null);
    const textExperienceRef = useRef(null);
    const textAndFirstRef = useRef(null);
    const textVeryRef = useRef(null);
    const textEnthusiasticRef = useRef(null);
    const textInRef = useRef(null);
    const textDesigningRef = useRef(null);
    const textDigitalRef = useRef(null);
    const textProductsRef = useRef(null);
    const textWithTwoRef = useRef(null);
    const textAnRef = useRef(null);
    const textIntuitiveRef = useRef(null);
    const textApproachRef = useRef(null);
    const textAndTwoRef = useRef(null);
    const textUserRef = useRef(null);
    const textNeedsRef = useRef(null);

    const linkConnectFirstRef = useRef(null);
    const linkConnectSecondRef = useRef(null);
    const linkShowcaseFirstRef = useRef(null);
    const linkShowcaseSecondRef = useRef(null);
    const linkSellingFirstRef = useRef(null);
    const linkSellingSecondRef = useRef(null);
    const linkSellingThirdRef = useRef(null);

    useEffect(() => {
        gsap.timeline()
            .from(textARef.current, {
                delay: 1,
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            })
            .from(textProfessionalRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textAsRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textUIUXRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textDesignerRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textWithFirstRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textMoreRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textThanRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textSixRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textYearsRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textOfRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textExperienceRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textAndFirstRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textVeryRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textEnthusiasticRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textInRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textDesigningRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textDigitalRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textProductsRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textWithTwoRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textAnRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textIntuitiveRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textApproachRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textAndTwoRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textUserRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            .from(textNeedsRef.current, {
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.8')
            
            .from(linkConnectFirstRef.current, {
                y: 32,
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            })
            .from(linkConnectSecondRef.current, {
                y: 32,
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.6')
            .from(linkShowcaseFirstRef.current, {
                y: 32,
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.6')
            .from(linkShowcaseSecondRef.current, {
                y: 32,
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.6')
            .from(linkSellingFirstRef.current, {
                y: 32,
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.6')
            .from(linkSellingSecondRef.current, {
                y: 32,
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.6')
            .from(linkSellingThirdRef.current, {
                y: 32,
                duration: 1,
                autoAlpha: 0,
                ease: Power1.easeOut
            }, '-=.6')
    }, [])

    return (
        <React.Fragment>
            <section className="container-fluid hero">
                <div className="container">
                    <h1 className="h2-heading-bold">
                        <span ref={textARef}>A&nbsp;</span>
                        <span ref={textProfessionalRef}>professional&nbsp;</span>
                        <span ref={textAsRef}>as&nbsp;</span>
                        <span ref={textUIUXRef}>UI/UX&nbsp;</span>
                        <span ref={textDesignerRef}>Designer&nbsp;</span>
                        <span ref={textWithFirstRef}>with&nbsp;</span>
                        <span ref={textMoreRef}>more&nbsp;</span>
                        <span ref={textThanRef}>than&nbsp;</span>
                        <span ref={textSixRef}>6&nbsp;</span>
                        <span ref={textYearsRef}>years&nbsp;</span>
                        <span ref={textOfRef}>of&nbsp;</span>
                        <span ref={textExperienceRef}>experience&nbsp;</span>
                        <span ref={textAndFirstRef}>and&nbsp;</span>
                        <span ref={textVeryRef}>very&nbsp;</span>
                        <span ref={textEnthusiasticRef}>enthusiastic&nbsp;</span>
                        <span ref={textInRef}>in&nbsp;</span>
                        <span ref={textDesigningRef}>designing&nbsp;</span>
                        <span ref={textDigitalRef}>digital&nbsp;</span>
                        <span ref={textProductsRef}>products&nbsp;</span>
                        <span ref={textWithTwoRef}>with&nbsp;</span>
                        <span ref={textAnRef}>an&nbsp;</span>
                        <span ref={textIntuitiveRef}>intuitive&nbsp;</span>
                        <span ref={textApproachRef}>approach&nbsp;</span>
                        <span ref={textAndTwoRef}>and&nbsp;</span>
                        <span ref={textUserRef}>user&nbsp;</span>
                        <span ref={textNeedsRef}>needs.</span>
                    </h1>
                    <div className="links">
                        <h6 className="subtitle2-light">CONNECT</h6>
                        <ul className="links-group">
                            <li>
                                <h2 className="h3-heading-bold" ref={linkConnectFirstRef}>
                                    <a href="https://www.linkedin.com/in/rahmanhp/" target="_blank" rel="noreferrer noopenner">LinkedIn</a>
                                </h2>
                            </li>
                            <li>
                                <h2 className="h3-heading-bold" ref={linkConnectSecondRef}>
                                    <a href="mailto:rahmanhape@gmail.com">Email</a>
                                </h2>
                            </li>
                        </ul>
                    </div>
                    <div className="links">
                        <h6 className="subtitle2-light">SHOWCASE</h6>
                        <ul className="links-group">
                            <li>
                                <h2 className="h3-heading-bold" ref={linkShowcaseFirstRef}>
                                    <a href="https://behance.net/rahmanhp" target="_blank" rel="noreferrer noopenner">Behance</a>
                                </h2>
                            </li>
                            <li>
                                <h2 className="h3-heading-bold" ref={linkShowcaseSecondRef}>
                                    <a href="https://dribbble.com/rahmanhp" target="_blank" rel="noreferrer noopenner">Dribbble</a>
                                </h2>
                            </li>
                        </ul>
                    </div>
                    <div className="links">
                        <h6 className="subtitle2-light">SELLING</h6>
                        <ul className="links-group">
                            <li>
                                <h2 className="h3-heading-bold" ref={linkSellingFirstRef}>
                                    <a href="https://thenounproject.com/rahmanhp/" target="_blank" rel="noreferrer noopenner">Noun Project</a>
                                </h2>
                            </li>
                            <li>
                                <h2 className="h3-heading-bold" ref={linkSellingSecondRef}>
                                    <a href="https://www.flaticon.com/authors/rahman-haryanto/" target="_blank" rel="noreferrer noopenner">Flaticon</a>
                                </h2>
                            </li>
                            <li>
                                <h2 className="h3-heading-bold" ref={linkSellingThirdRef}>
                                    <a href="https://www.iconfinder.com/rahmanhp/" target="_blank" rel="noreferrer noopenner">Iconfinder</a>
                                </h2>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Hero;